.pulsating-light-container {
  width: 50vw;
  height: 10vw;
  position: fixed;
  left: 25vw;
}

.pulsating-light {
  /* width: 100%;
    height: 100%; */

  border-radius: 100%;
  animation: pulse 1.5s infinite alternate; /* Adjust animation duration and timing as needed */
  box-shadow: 0px -3vw 9vh #ffbf12;
}

@keyframes pulse {
  0% {
    opacity: 0.3; /* Starting opacity */
  }
  100% {
    opacity: 1; /* Ending opacity */
  }
}
