.LandingPage {
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
.LandingPage__OpeningSlide {
  /* color: black; */
  /* min-height: 264px; */
  position: relative;
  overflow: hidden;
  /* height: 100vh; */
  width: 100vw;
  margin-bottom: 5.5em;
  /* box-shadow: inset -20vw -1vw 15vw 9vw rgb(0 0 0); */
}
.LandingPage__OpeningSlideTextContainer{
  display: flex;
  flex-direction: column;
}
.LandingPage__OpeningSlideContainer {
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100vw;
}
.LandingPage__OpeningSlideText {
  margin:  auto 0vw;
}
@media (min-width: 500px) {
 
  .LandingPage__OpeningSlideTextContainer {
    width: fit-content;
    margin: 0 auto 2.5em;
    display: flex;
  }
  .LandingPage__ShortLogo {
    width: 3.5em;
    margin:  0 auto 0.5em;
    display: inline;
  }
  .LandingPage__OpeningSlideLogoText {
    font-size: 1.7em;
    text-transform: none;
    margin: auto 0px auto 0.35em;
    display: inline;
  }
}
@media (max-width: 500px) {
 
  .LandingPage__OpeningSlideTextContainer {
    width: fit-content;
    margin: 0 auto 2em;
    display: flex;
  }
  .LandingPage__ShortLogo {
    width: 2.5em;
    margin: 0 auto 0.25em;
    display: inline;
  }
  .LandingPage__OpeningSlideLogoText {
    font-size: 1.2em;
    text-transform: none;
    margin: auto 0px auto 0.35em;
    display: inline;
  }
}
.LandingPage__WhereTxt {
  font-size: 0.9em;
  line-height: 1em;
  margin: 1.8em 0 0;
  text-shadow: 0 0 0.1vw #fff, 0 0 0.05vw #fff, 0 0 1.1vw #ffbf12;
  text-align: center;
  width: fit-content;
  position: relative;
  margin: 0 auto;
}
.LandingPage__OpeningSlideImgContainer {
  /* width: 50vw; */
  /* height: 100vh; */
  position: relative;
}
.LandingPage__OpeningSlideImgContainer::after {
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  -ms-box-shadow: inset 0 0 8em 2em #121212, inset 0px -6em 4em 1em #121212;
  -o-box-shadow: inset 0 0 8em 2em #121212, inset 0px -6em 4em 1em #121212;
  box-shadow: inset 0 0 8em 2em #121212, inset 0px -6em 4em 1em #121212;
}
.LandingPage__OpeningSlideImg {
  top: 4vw;
  right: 0;
  height: 100vh;
}
.LandingPage__ScrollDownBtn {
  position: fixed;
  bottom: 2em;
  left: 2em;
  z-index: 5;
  width: 2.3em;
  transform: rotate(360deg);
  transition: all 0.6s;
}

.LandingPage__ScrollDownBtn:hover {
  /* background-color: #ffc01248;
    border: 0.25em solid #ffc01248;
    box-shadow: none; */
  cursor: pointer;
  /* border-radius: 100% ; */
}
.LandingPage__ScrollToSlide6Btn {
  position: fixed;
  bottom: 2em;
  left: 4em;
  z-index: 5;
  width: 2.3em;
}

.LandingPage__ScrollToSlide6Btn:hover {
  /* background-color: #ffc01248;
    border: 0.25em solid #ffc01248;
    box-shadow: none; */
  cursor: pointer;
  /* border-radius: 100% ; */
}
.HomeDesktop__ScrollDownBtn--up {
  transform: rotate(180deg);
  transition: all 0.6s;
}

.LandingPage__Slide1 {
  opacity: 1;
  position: relative;
  /* margin-top: -50vh; */
  top: 0;
  left: 0;
  padding: 0;
  width: 100%;
  /* z-index: 0; */
  /* height: 190vh; */
  color: #ffbf12 !important;
  text-align: center;
  transition: "opacity 0.2s";
}
.LandingPage__Slide1Container {
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
}
.LandingPage__Slide1Text1 {
  margin: 0 0 1em;
  font-size: 1.2em;
  line-height: 1.15em;
  opacity: 0;
  transition: opacity 0.75s;
}
.LandingPage__Slide1Text1 i {
  font-size: 1.5em;
  line-height: 1.25em;
  font-style: normal;
  color: #fff;
  text-shadow: 0 0 0.1vw #fff, 0 0 0.05vw #fff, 0 0 1.1vw #ffbf12;
}
.LandingPage__Slide1Text2 {
  margin: 5vh 0 0 0;
  font-size: 1.2em;
  line-height: 1.15em;
  opacity: 0;
  transition: opacity 1.5s;
}
.LandingPage__Slide1Text2 i {
  font-size: 1.5em;
  line-height: 1.25em;
  font-style: normal;
  color: #fff;
  text-shadow: 0 0 0.1vw #fff, 0 0 0.05vw #fff, 0 0 1.1vw #ffbf12;
}
.LandingPage__Slide1Text3 {
  opacity: 0;
  margin: 0;
  line-height: 1.2em;
  font-size: 1.3em;
  transition: opacity 1.5s;
}
/* #OpeningImgHD::after {
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  -ms-box-shadow: inset 0 0 8em 2em #121212, inset 0px -6em 4em 1em #121212;
  -o-box-shadow: inset 0 0 8em 2em #121212, inset 0px -6em 4em 1em #121212;
  box-shadow: inset 0 0 8em 2em #121212, inset 0px -6em 4em 1em #121212;
} */
.LandingPage__Slide4__JoinTxt {
  width: fit-content;
  line-height: 0.8em;
  font-size: 1.1em;
  text-align: center;
  margin: 0 auto !important;
  display: block;
  padding: 0 !important;
}

.LandingPage__Footer__LinksContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 3vh;
  z-index: 100;
}
.LandingPage__Footer__Links {
  display: flex;
}
.LandingPage__Footer__Links p {
  /* font-size: 0.6em; */
  font-weight: normal;
  margin: 0 0.8em;
  line-height: 1em;
}
.LandingPage__Footer__SMLink {
  margin: 0 1.2vw;
  width: 3em;
  height: 3em;
}
@media (max-width: 500px) {
  .LandingPage__Footer__SMLink {
    margin: 0 0.8em;
    width: auto;
    height: 2.25em;
  }
}

.LandingPage__LongLogo {
  display: block;
  width: 20em;
  margin: 0.5em auto;
}
.LandingPage__FooterTxt {
  font-size: 1em;
  line-height: 1.7em;
  margin: 2vw 5vw 3vw;
  text-align: center;
}
#WhatsappLogoBWHD:hover {
  cursor: pointer;
}
.LandingPage__WishlistSelect {
  cursor: pointer;
  margin: 0 auto;
  position: relative;
  display: block;
  color: white;
  background: #121212;
  border-radius: 50px;
  padding: 6px;
  font-size: 0.65em;
  text-transform: capitalize;
  font-weight: lighter;
}
.LandingPage__WishListCheckBoxContainer {
  margin: 0 auto;
  position: relative;
  display: block;
  font-size: 0.65em;
  text-transform: none;
  width: fit-content;
  font-weight: lighter;
}
@media (max-width: 500px) {
  .LandingPage__WishlistSelect {
    margin: 0 auto;
    position: relative;
    display: block;
    color: white;
    background: #121212;
    border-radius: 50px;
    padding: 6px;
    font-size: 0.55em;
    text-transform: capitalize;
    font-weight: lighter;
  }
  .LandingPage__WishListCheckBoxContainer {
    margin-top: 2vh;
    position: relative;
    display: block;
    font-size: 0.55em;
    line-height: 1.5em;
    text-transform: none;
    width: fit-content;
    font-weight: lighter;
    padding: 0 10vw;
  }
}
/* When the checkbox is checked, add a blue background */
.LandingPage__WishListCheckBoxContainer * {
  cursor: pointer;
}
input[type="checkbox"] {
  accent-color: #ffbf12;
}

/* to update - copy from 'frontend' project desktop/home/style.css */
.HomeDesktop {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
}

.HomeDesktop__Header {
  width: 100vw;
  height: 3.3em;
  background: #1f1f1f;
}
.HomeDesktop__LongLogo {
  margin: 0.65em;
  height: 2.15em;
}

/* @media (max-width: 580.98px) {
  #OpeningDiv {
    min-height: 200px;
    height: 240px;
    padding-top: 35px;
  }
}
@media (min-width: 580.98px) {
  #OpeningDiv {
    min-height: 200px;
    height: 440px;
    padding-top: 35px;
  }
}
@media (min-width: 768px) {
  #OpeningDiv {
    min-height: 520px;
    /* margin-bottom: 4rem; 
  }
}
@media (min-width: 1000px) {
  #OpeningDiv {
    /* width: 80vw; */
/* height: 110vh; */
/* margin: auto; 
  } 
*/
.OpeningSlideContainer {
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100vw;
}
.OpeningSlideImgContainer {
  /* width: 50vw; */
  /* height: 100vh; */
  position: relative;
}
.OpeningSlideImgContainer::after {
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  -ms-box-shadow: inset 0 0 8em 2em #121212, inset 0px -6em 4em 1em #121212;
  -o-box-shadow: inset 0 0 8em 2em #121212, inset 0px -6em 4em 1em #121212;
  box-shadow: inset 0 0 8em 2em #121212, inset 0px -6em 4em 1em #121212;
}

#OpeningDiv {
  color: black;
  /* min-height: 264px; */
  position: relative;
  overflow: hidden;
  /* height: 100vh; */
  width: 100vw;
  margin-bottom: 5.5em;
  /* box-shadow: inset -20vw -1vw 15vw 9vw rgb(0 0 0); */
}
.OpeningSlideText {
  margin: auto 0;
}
#OpeningImgHD {
  /* position: absolute; */
  top: 4vw;
  right: 0;
  /* width: 50%; */
  height: 100vh;
  /* box-shadow: inset -20vw -1vw 15vw 9vw rgb(0 0 0); */
  /* box-shadow: 0 0 8px 8px white inset; */
}

#ShortLogoHD {
  width: 9em;
}
#WhereTxtHD,
#InPeopleTxtHD {
  font-size: 1em;
  /* line-height: 95% ; */
  margin: 0.8em 0 0;
}
#InPeopleTxtHD {
  font-size: 1em;
  /* line-height: 95% ; */
  margin: 0.1em 0 0;
}
#ScrollDivHD {
  width: 100%;
  height: 1em;
  overflow: hidden;
}
.HomeDesktop__ScrollDownBtn {
  box-sizing: content-box;
  position: fixed;
  bottom: 1em;
  left: 1em;
  z-index: 5;
  width: 2em;
  transform: rotate(360deg);
  transition: all 0.6s;
}
.HomeDesktop__ScrollDownBtn:hover {
  /* background-color: #ffc01248;
  border: 0.25em solid #ffc01248;
  box-shadow: none; */
  cursor: pointer;
  /* border-radius: 100% ; */
}
.HomeDesktop__ScrollDownBtn--up {
  transform: rotate(180deg);
  transition: all 0.6s;
}

#ScrollDivHD p {
  font-size: 0.6em;
  line-height: 1.7em;
  margin: 0;
  text-shadow: 0 0 0.1vw #fff, 0 0 3px #fff, 0 0 6px #ffbf12,
    1px 0px 13px #ffbf12;
}
.HomeDesktop__Slide1__MoneyEmoji,
.HomeDesktop__Slide2__HumbleEmoji,
.HomeDesktop__Slide3__FireEmoji,
.HomeDesktop__Slide4__StarsEyesEmoji {
  display: block;
  margin: 0em auto 0.35em;
  width: 3em;
}
.HomeDesktop__Slide h1 {
  font-size: 0.77em;
  color: #ffbf12;
  text-align: center;
  text-transform: capitalize;
  margin: 0;
}
.HomeDesktop__Slide h2 {
  font-size: 0.67em;
  line-height: 4vw;
  padding: 0 1em;
  line-height: 1.2em;
  /* -webkit-text-stroke: 0.03em white; */
  color: white;
  text-transform: capitalize;
  margin: 0 auto;
  text-align: center;
}
.HomeDesktop__Slide p {
  position: relative;
  font-size: 0.5em;
  line-height: 1.5em;
  padding: 0 5vw;
  text-align: center;
  color: white;
  text-transform: none;
  margin-bottom: 50vh;
}
.HomeDesktop__Slide4__EmailInputContainer {
  margin: 1em auto;
}
.HomeDesktop__Slide4__EmailInput {
  display: block;
  line-height: 2.25em;
  font-size: 0.5em;
  text-align: center;
  font-family: sans-serif;
  border-radius: 100em;
  border: 0;
  width: 80vw;
  margin: 0 auto 7vw;
}
.HomeDesktop__Slide4__DoneButton {
  width: 1.35em;
  margin: 0 auto;
  display: block;
}
.HomeDesktop__Slide4__DoneButton:hover {
  cursor: pointer;
}
.HomeDesktop__Slide4__LoginLink {
  font-size: 2em;
}
.HomeDesktop__Slide4__LoginLink i {
  font-style: normal;
  color: #ffbf12;
  cursor: pointer;
}
#BottomDivHD {
  position: relative;
  top: 0vw;
  left: 0vw;
  width: 100vw;
  /* margin-top: 10vw; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#LogoDescEndHD {
  margin: 2em 0;
  padding: 1em 0;
}
#LongLogoEndHD {
  width: 45vw;
  margin: 0 5vw;
}
#EndDescriptionHD {
  font-size: 0.8em;
  line-height: 1.7em;
  margin: 2vw 5vw 3vw;
}
#EndDescriptionHD i {
  font-style: normal;
  color: #ffbf12;
}

#PoliciesRefHD {
  padding: 0 5vw 1em;
  text-shadow: 0 0 0.1vw #fff, 0 0 0.05vw #fff, 0 0 1.1vw #ffbf12,
    1px 0px 1vw #ffbf12;
  font-size: 1.4em;
  line-height: 0.75em;
  margin: 0vw 0 35vw;
  /* padding-bottom: 10vh; */
  /* height: 70vw; */
  /* margin: 7vw 0; */
}
#FindUsEndHD {
  margin: 5vw;
  display: flex;
  position: relative;
  justify-content: flex-start;
}
.SMLogosBWHD {
  margin-right: 4vw;
  width: 9vw;
  height: 9vw;
}
#LineEndHD {
  position: relative;
  /* top: 50vw; */
  margin-left: 5vw;
}


@media (min-width: 500px) {
  #ShortLogoHD {
    width: 11em;
    margin: 0 auto 4em;
    display: block;
  }
  #WhereTxtHD {
    font-size: 0.9em;
    /* line-height: 95%; */
    margin: 1.8em 0 0;
    text-shadow: 0 0 0.1vw #fff, 0 0 0.05vw #fff, 0 0 1.1vw #ffbf12;
  }
  #ScrollDivHD {
    width: 100%;
    height: 1.2em;
    overflow: hidden;
  }

  #ScrollDivHD p {
    font-size: 1em;
    line-height: 1.2em;
    margin: 0;
    text-shadow: 0 0 0.1vw #fff, 0 0 3px #fff, 0 0 6px #ffbf12,
      1px 0px 13px #ffbf12;
  }
  #InPeopleTxtHD {
    font-size: 1.7em;
    /* line-height: 95% ; */
    margin: 0;
  }
  .HomeDesktop__ScrollDownBtn {
    position: fixed;
    bottom: 2em;
    left: 2em;
    z-index: 5;
    width: 2.3em;
    transform: rotate(360deg);
    transition: all 0.6s;
  }
  .HomeDesktop__ScrollDownBtn--up {
    transform: rotate(180deg);
    transition: all 0.6s;
  }
  .HomeDesktop__Slide {
    -ms-box-shadow: inset 0px 0px 10px 7px #121212;
    -o-box-shadow: inset 0px 0px 10px 7px #121212,
      inset 0px -18px 20px 20px #121212;
    box-shadow: inset 1px 1px 20px 20px #121212,
      inset 0px -18px 20px 20px #121212;
  }
  .HomeDesktop__Slide1__MoneyEmoji,
  .HomeDesktop__Slide2__HumbleEmoji,
  .HomeDesktop__Slide3__FireEmoji,
  .HomeDesktop__Slide4__StarsEyesEmoji {
    display: block;
    margin: 0em auto 0.1em;
    width: 3em;
  }
  .HomeDesktop__Slide h1 {
    font-size: 1em;
    color: #ffbf12;
    text-align: center;
    text-transform: capitalize;
    margin: 0;
  }
  .HomeDesktop__Slide h2 {
    font-size: 0.85em;
    /* -webkit-text-stroke: 0.03em white; */
    color: white;
    text-transform: capitalize;
    margin: 0 auto;
    text-align: center;
  }
  .HomeDesktop__Slide p {
    font-size: 0.6em;
    line-height: 1.45em;
    padding: 0 11.5em;
    text-align: center;
    color: white;
    text-transform: none;
  }
  .HomeDesktop__Slide4__EmailInputContainer {
    display: flex;
    justify-content: center;
    margin: 3vh auto;
  }
  .HomeDesktop__Slide4__EmailInput {
    display: block;
    line-height: 2em;
    font-size: 0.5em;
    text-align: center;
    font-family: sans-serif;
    border-radius: 1em;
    border: 0;
    margin: 0;
    width: 26vw;
    height: 2.5em;
  }
  .HomeDesktop__Slide4__DoneButton {
    width: 1.2em;
    height: 1.2em;
    margin: 0;
    margin-left: 0.5em;
  }
  #LogoDescEndHD {
    /* width: 47vw; */
    margin: 2em 0;
  }
  #LongLogoEndHD {
    display: block;
    width: 20em;
    margin: 0.5em auto;
  }
  #EndDescriptionHD {
    font-size: 0.8em;
    line-height: 1.5em;
    margin: 0 2em;
    text-align: center;
  }
  .BottomDivHDFlex {
    display: flex;
    justify-content: space-evenly;
  }

  #PoliciesRefHD {
    padding: 0 5vw;
    width: 50vw;
    text-shadow: 0 0 0.1vw #fff, 0 0 0.05vw #fff, 0 0 1.1vw #ffbf12,
      1px 0px 1vw #ffbf12;
    font-size: 4em;
    /* height: 70vw; */
    /* margin: 7vw 0; */
    margin: auto 0;
  }
  #PoliciesRefHD p {
    margin: 1em 0;
    font-size: 0.5em;
  }
  #LineEndHD {
    position: relative;
    /* top: 50vw; */
    margin: 2em 2em 0;
  }
  #FindUsEndHD {
    margin: 1em 2em;
    display: flex;
    position: relative;
    justify-content: flex-start;
  }
  .SMLogosBWHD {
    margin-right: 3vw;
    width: 4.5vw;
    height: 4.5vw;
  }
}

/* #ScrollDivHD {
  position: absolute;
  top: 72.5vh;
  left: 3vw;
  width: 15vw;
  height: 4vw;
  overflow: hidden;
} */

/* #InPeopleTxtHD {
  position: absolute;
  top: 80.7vh;
  left: 3vw;
  font-size: 7vh;
  line-height: 7vh;
  margin: 0;
} */
.HomeDesktop__Slide {
  position: relative;
  width: 100vw;
  -ms-box-shadow: inset 0px 0px 10px 7px #121212;
  -o-box-shadow: inset 0px 0px 10px 7px #121212,
    inset 0px -18px 20px 20px #121212;
  box-shadow: inset 1px 1px 20px 20px #121212, inset 0px -18px 20px 20px #121212;
}

#HomeDesktop__Slide4__JoinTxt {
  font-size: 1em;
  text-align: center;
  text-shadow: -0.07em 0.07em 0em #121212, -0.1em 0.1em 0em white;
  margin: 0 auto;
}

.HomeDesktop__Slide4__AppComingSoonContainer {
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  height: 33vw;
  background-color: white;
  z-index: 5;
  margin: 6em 0;
}

#ForTheTxtHD {
  position: absolute;
  top: 8vw;
  left: 4vw;
  font-size: 3vw;
  line-height: 3.5vw;
  margin: 0;
  color: #ffbf12;
}
/* @media (min-width: 1000px) {
  #ForTheTxtHD {
    position: absolute;
    top: 8vw;
    left: 4vw;
    font-size: 3vw;
    line-height: 3.5vw;
    margin: 0;
    color: #ffbf12;
  }
}
@media (min-width: 1100px) {
  #ForTheTxtHD {
    position: absolute;
    top: 5vw;
    left: 4vw;
    font-size: 3vw;
    line-height: 3.5vw;
    margin: 0;
    color: #ffbf12;
  }
} */

#PSAppPicHD {
  position: absolute;
  top: -14vw;
  left: 36vw;
  width: 32vw;
}
/* @media (min-width: 1000px) {
    #PSAppPicHD {
        position: absolute;
        top: -14vw;
        left: 36vw;
        width: 32vw;
    }
} */
@media (min-width: 1100px) {
  #PSAppPicHD {
    position: absolute;
    top: -10vw;
    left: 36vw;
    width: 25vw;
  }
}

#OurAppHD {
  position: absolute;
  top: 6vw;
  left: 64vw;
  font-size: 4vw;
  line-height: 5vw;
  margin: 0;
  color: black;
}
/* @media (min-width: 1000px) {
    #OurAppHD {
        position: absolute;
        top: 6vw;
        left: 64vw;
        font-size: 4vw;
        line-height: 5vw;
        margin: 0;
        color: black;
    }
} */
@media (min-width: 1100px) {
  #OurAppHD {
    position: absolute;
    top: 5vw;
    left: 64vw;
    font-size: 4vw;
    line-height: 5vw;
    margin: 0;
    color: black;
  }
}

#GooglePlayHD {
  position: absolute;
  top: 19vw;
  left: 63vw;
  width: 17vw;
}
#AppStoreHD {
  position: absolute;
  top: 19vw;
  left: 80vw;
  width: 17vw;
}
/* @media (min-width: 1000px) {
    
} */
@media (min-width: 1100px) {
  #GooglePlayHD {
    position: absolute;
    top: 17vw;
    left: 63vw;
    width: 17vw;
  }
  #AppStoreHD {
    position: absolute;
    top: 17vw;
    left: 80vw;
    width: 17vw;
  }
}

/* @media (min-width: 1000px) {
  #BottomDivHD {
    position: relative;
    top: -8vw;
    left: 0vw;
    width: 100vw;
    height: 76vw;
    margin-top: 7vw;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}
@media (min-width: 1100px) {
  #BottomDivHD {
    position: relative;
    top: -8vw;
    left: 0vw;
    width: 100vw;
    height: 76vw;
    margin-top: 7vw;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
} */

/* @media (min-width: 1000px) {
  #EndDescriptionHD {
    position: absolute;
    top: 13vw;
    left: 5vw;
    font-size: 1.8vw;
    line-height: 3vw;
    margin: 0;
  }
}
@media (min-width: 1100px) {
  #EndDescriptionHD {
    position: absolute;
    top: 13vw;
    left: 5vw;
    font-size: 1.8vw;
    line-height: 3vw;
    margin: 0;
  }
} */

/* @media (min-width: 1000px) {
  #LineEndHD {
    position: absolute;
    top: 34vw;
    left: 5vw;
  }
}
@media (min-width: 1100px) {
  #LineEndHD {
    position: absolute;
    top: 34vw;
    left: 5vw;
  }
} */

/* @media (min-width: 1000px) {
  #FindUsEndHD {
    position: absolute;
    top: 48vw;
    left: 0vw;
    width: 50vw;
    height: 20vw;
  }
}
@media (min-width: 1100px) {
  #FindUsEndHD {
    position: absolute;
    top: 48vw;
    left: 0vw;
    width: 50vw;
    height: 20vw;
  }
} */

/* #WhatsappLogoBWHD {
  position: absolute;
  top: 2vw;
  left: 5vw;
  width: 8vw;
}

#FacebookLogoBWHD {
  position: absolute;
  top: 2vw;
  left: 18vw;
  width: 8vw;
}

#InstagramLogoBWHD {
  position: absolute;
  top: 2vw;
  left: 31vw;
  width: 8vw;
}

#YoutubeLogoBWHD {
  position: absolute;
  top: 2vw;
  left: 44vw;
  width: 8vw;
} */

/* #TsAndCsRefHD {
    position: absolute;
    top: 5vw;
    left: 5vw;
    font-size: 7vw;
    line-height: 7vw;
    margin: 0;
}

#PrivacyPolicyRefHD {
    position: absolute;
    top: 15vw;
    left: 5vw;
    font-size: 7vw;
    line-height: 7vw;
    margin: 0;
} */

/* #TsAndCsRefHD {
    position: absolute;
    top: 27vw;
    left: 5vw;
    font-size: 7vw;
    line-height: 7vw;
    margin: 0;
}

#TsAndCsRefHD {
    position: absolute;
    top: 39vw;
    left: 5vw;
    font-size: 7vw;
    line-height: 7vw;
    margin: 0;
} */
.AppComingSoonHD {
  position: relative;
}
